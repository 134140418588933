<template>
    <div>
        <div class="form-check">
            <input class="form-check-input" type="radio" name="bitselect" v-model="filterItem.selectedValue" value="istrue" :id="id">
            <label class="form-check-label" :for="id">
                 {{filterItem.getOperatorTitle('istrue')}}
            </label>
        </div>

        <div class="form-check">
            <input class="form-check-input" type="radio" name="bitselect" v-model="filterItem.selectedValue" value="isfalse" :id="id + '1'">
            <label class="form-check-label" :for="id+'1'">
                {{filterItem.getOperatorTitle('isfalse')}}
            </label>
        </div>
    </div>
</template>

<script setup>
    const props = defineProps({
        filterItem: null,
        filterObject: null,
        dropdown: null,
        hideOperators:null
    });

    const id = 'checkboxFilter' + new Date() + '_';
</script>